<template>
  <NuxtLazyHydrate never>
    <div
      v-if="banners && banners.length > 0"
      class="lg:px-16 lg:mx-auto lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl"
    >
      <div
        class="flex flex-nowrap overflow-auto p-4 border-b space-x-3 lg:p-0 lg:pt-8 lg:border-b-0 lg:grid lg:grid-cols-3 lg:gap-3 lg:space-x-0 rtl:space-x-reverse"
      >
        <a
          v-for="item in banners"
          :key="`banner-${item.id}`"
          :href="item.url || '#'"
          class="w-11/12 flex-shrink-0 flex-grow-0 md:w-5/12 lg:w-full"
        >
          <div class="aspect-w-16 aspect-h-9 border rounded-lg overflow-hidden">
            <img
              :src="item.mediaUrl"
              loading="lazy"
              class="w-full h-full object-cover"
            />
          </div>
        </a>
      </div>
    </div>
  </NuxtLazyHydrate>
</template>

<script>
import { useVepaarStore } from "@/store/store.js";
import { mapState } from "pinia";

export default {
  computed: {
    ...mapState(useVepaarStore, ["banners"]),
  },
};
</script>
